import { ofType } from "redux-observable";
import {
  catchError,
  concatMap,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators";
import { iif, of, merge } from "rxjs";
import { push, replace } from "connected-react-router";
import { matchPath } from "react-router";
import get from "lodash/get";
import queryString from "query-string";

import { getPathnameWithoutLocale } from "config/languages";
import * as bookingFlowRoutes from "BookingFlow/bookingFlowRoutes";
import * as profileRoutes from "Profile/profileRoutes";
import { getSearchResults$ } from "api/tretail/searchResults";
import {
  clearPreferredPartnerDetails,
  fetchPreferredPartnerDetailsFulfilled,
  fetchPreferredPartnerDetails$,
  fetchPreferredPartnerDetailsFailed,
} from "store/preferredPartnerDetails";
import {
  selectProperty,
  selectIsPropertyBookableInLocale,
} from "store/properties";
import {
  checkOccupancy,
  checkEmployeePropertyLimits,
  checkEmployeeRoomNights,
  getAdjustedOccupancy,
  getQueryStringFromSearchFormValues,
  hasEmployeeSameDayBooking,
  isEmployeeRate,
} from "BookingFlow/utils";
import { clearTermsAndConditionsContent } from "store/termsAndConditionsContent";
import { fetchOffersTermsAndConditionsContentFulfilled } from "store/offersTermsAndConditionsContent";
import { resetBookings } from "store/bookings";
import { setCurrencyCode } from "store/exchangeRates";
import { fetchedSearchResultsRouter } from "utils";
import { selectPropertyCurrency } from "store/propertyContent";
import { getGeoIPCode } from "utils/geoIP";
import { TECHNICAL_ERROR_MESSAGE } from "fixtures/constants";
import checkEmployeeSearchParams, {
  EMPLOYEE_SEARCH_ERRORS,
} from "store/searchResults/checkEmployeeSearchParams";
import validateSearchParamAdults from "BookingFlow/utils/validateSearchParamAdults";
import catchInternalServerError from "store/catchInternalServerError";
import { getOffersTermsAndConditionsContent$ } from "api/content";
import {
  fetchSearchResults,
  fetchSearchResultsFulfilled,
  fetchSearchResultsFailed,
  fetchSearchResultsCancel,
  missingLanguage,
  occupancyError,
} from "../searchResults.slice";
import getOfferCodesFromHotelResultsResponse from "../getOfferCodesFromHotelResultsResponse";

const validateEmployeeSearchParams = ({
  locale,
  property,
  searchParams,
  state,
}) => {
  const employeeId = get(state, ["profile", "data", "extension", "employeeId"]);
  const employeeProfile = get(state, ["employeeProfile", "data"], {});
  if (
    !employeeId ||
    employeeId === "0" ||
    employeeId !== employeeProfile.workdayId
  ) {
    return of(
      fetchSearchResultsFailed({
        apiErrors: [
          {
            errorCode:
              "Your session has expired. Please sign again to continue.",
          },
        ],
      }),
      push({
        pathname: bookingFlowRoutes.planYourStay.to({ locale }),
        search: getQueryStringFromSearchFormValues(searchParams),
      })
    );
  }

  const checkSearchParamsResult = checkEmployeeSearchParams({
    searchParams,
    property,
    employeeProfile,
    workplaces: get(state, ["workplaces", "data"], []),
  });
  if (checkSearchParamsResult) {
    return of(
      fetchSearchResultsFailed({
        errors: [{ errorCode: checkSearchParamsResult }],
      }),
      push({
        pathname: profileRoutes.employeeProfilePath.to({ locale }),
        search: getQueryStringFromSearchFormValues(searchParams),
      })
    );
  }

  return false;
};

const validateEmployeeStays = ({
  locale,
  searchParams,
  employeeStayHistory = {},
  globalSettings,
}) => {
  if (
    !employeeStayHistory.totalResults &&
    employeeStayHistory.totalResults !== 0
  ) {
    return of(
      fetchSearchResultsFailed({
        apiErrors: [
          {
            errorCode: EMPLOYEE_SEARCH_ERRORS.TECHNICAL,
          },
        ],
      }),
      push({
        pathname: profileRoutes.employeeProfilePath.to({ locale }),
        search: getQueryStringFromSearchFormValues(searchParams),
      })
    );
  }

  const sameDayBookingResult = hasEmployeeSameDayBooking({
    searchParams,
    bookings: employeeStayHistory.bookingSummaries,
  });
  if (sameDayBookingResult) {
    return of(
      fetchSearchResultsFailed({
        errors: [{ errorCode: sameDayBookingResult }],
      }),
      push({
        pathname: profileRoutes.employeeProfilePath.to({ locale }),
        search: getQueryStringFromSearchFormValues(searchParams),
      })
    );
  }

  const propertyLimitsResult = checkEmployeePropertyLimits({
    searchParams,
    stayHistory: employeeStayHistory.bookingSummaries,
    globalSettings,
  });

  if (propertyLimitsResult) {
    return of(
      fetchSearchResultsFailed({
        errors: [propertyLimitsResult],
      }),
      push({
        pathname: profileRoutes.employeeProfilePath.to({ locale }),
        search: getQueryStringFromSearchFormValues(searchParams),
      })
    );
  }

  const roomNightsResult = checkEmployeeRoomNights({
    searchParams,
    stayHistory: employeeStayHistory.bookingSummaries,
    globalSettings,
  });

  if (roomNightsResult) {
    return of(
      fetchSearchResultsFailed({
        errors: [roomNightsResult],
      }),
      push({
        pathname: profileRoutes.employeeProfilePath.to({ locale }),
        search: getQueryStringFromSearchFormValues(searchParams),
      })
    );
  }

  return false;
};

export const fetchSearchResults$ = ({
  isAllInPricing = false,
  locale = "en",
  property,
  searchParams: initialSearchParams,
  refetch = false,
  state,
}) => {
  const searchParams = {
    ...initialSearchParams,
    flexDatesWindow: !property?.disableFlexDates
      ? initialSearchParams.flexDatesWindow
      : undefined,
  };

  const isPreferredPartners = Boolean(searchParams.ppMode);
  const employeeMode = isEmployeeRate(searchParams);

  if (employeeMode) {
    // Validate Employee Search Parameters
    const employeeSearchParamError = validateEmployeeSearchParams({
      locale,
      property,
      searchParams,
      state,
    });
    if (employeeSearchParamError) {
      return employeeSearchParamError;
    }
  }

  const searchParamsFromQueryString = queryString.parse(
    state.router.location.search
  );
  const searchParamsError = validateSearchParamAdults(
    searchParamsFromQueryString
  );
  if (searchParamsError) {
    return of(
      fetchSearchResultsFailed({ errors: [searchParamsError] }),
      push({
        pathname: employeeMode
          ? profileRoutes.employeeProfilePath.to({ locale })
          : bookingFlowRoutes.planYourStay.to({ locale }),
        search: state.router.location.search,
      })
    );
  }

  return iif(
    () => isPreferredPartners,
    fetchPreferredPartnerDetails$({ searchParams }),
    of(undefined)
  ).pipe(
    mergeMap((ppDetails) => {
      if (Boolean(searchParams.ppMode) && ppDetails?.success !== "true") {
        return [
          fetchPreferredPartnerDetailsFailed(),
          fetchSearchResultsFailed(),
          push({
            pathname: bookingFlowRoutes.chooseYourRoom.to({ locale }),
            search: getQueryStringFromSearchFormValues({
              ...searchParams,
              ppMode: undefined,
            }),
          }),
        ];
      }

      const checkOccupancyResult =
        property &&
        checkOccupancy({
          rooms: searchParams.rooms,
          property,
          isPreferredPartners,
          employeeMode,
        });

      if (checkOccupancyResult) {
        return employeeMode
          ? [
              fetchSearchResultsFailed({
                errors: [
                  { errorCode: EMPLOYEE_SEARCH_ERRORS.NO_AVAILABLE_ROOMS },
                ],
              }),
              push({
                pathname: profileRoutes.employeeProfilePath.to({ locale }),
                search: getQueryStringFromSearchFormValues(searchParams),
              }),
            ]
          : [
              fetchSearchResultsFailed(),
              occupancyError(checkOccupancyResult),
              replace({
                pathname: bookingFlowRoutes.planYourStay.to({ locale }),
                search: getQueryStringFromSearchFormValues({
                  ...searchParams,
                  rooms: getAdjustedOccupancy({
                    rooms: searchParams.rooms,
                    maxAdultOccupancy: property.maxAdultOccupancy,
                    maxChildOccupancy: property.maxChildOccupancy,
                    maxNumberOfBodies: property.maxNumberOfBodies,
                    maxRooms: property.maxRooms,
                    isPreferredPartners,
                  }),
                }),
              }),
            ];
      }

      return iif(
        () => employeeMode,
        /* temporarily remove request to fetch employee stay history - validation only done on confirmation
        fetchStayHistory$({
          locale,
          startDate: getDateForEmployeeRoomNights(searchParams?.dates),
        }),
        */
        of({ totalResults: 0, bookingSummaries: [] }),
        of(undefined)
      ).pipe(
        mergeMap((employeeStayHistory) => {
          if (employeeMode) {
            // Validate Employee Room Nights using Stay History
            const employeeStayError = validateEmployeeStays({
              locale,
              searchParams,
              employeeStayHistory,
              globalSettings: state?.globalSettings?.data,
            });
            if (employeeStayError) {
              return employeeStayError;
            }
          }

          const shouldChangeRoute = [
            bookingFlowRoutes.planYourStay.path,
            bookingFlowRoutes.chooseYourRoom.path,
          ].some(
            (path) =>
              matchPath(state.router.location.pathname, {
                path,
              })?.isExact
          );

          return merge(
            searchParams.ppMode
              ? of(fetchPreferredPartnerDetailsFulfilled(ppDetails))
              : of(clearPreferredPartnerDetails()),
            getSearchResults$({
              isAllInPricing,
              searchParams,
              locale,
            }).pipe(
              switchMap(
                ({
                  createHotelSearchResponse,
                  getHotelSearchResultsResponse,
                }) => {
                  const roomTypeIds = getOfferCodesFromHotelResultsResponse(
                    getHotelSearchResultsResponse
                  );
                  return getOffersTermsAndConditionsContent$({
                    locale,
                    hotelCode: searchParams.hotelCode,
                    checkinDate: searchParams.dates.checkIn,
                    checkoutDate: searchParams.dates.checkOut,
                    promoCode: searchParams.promoCode,
                    roomTypeIds,
                  }).pipe(
                    switchMap((offersTermsAndConditionsContent) => {
                      return of(getGeoIPCode()).pipe(
                        concatMap((geoIpCountryCode) => [
                          fetchSearchResultsFulfilled({
                            createHotelSearchResponse,
                            data: getHotelSearchResultsResponse,
                            isAllInPricingResults: isAllInPricing,
                            refetch,
                          }),
                          fetchOffersTermsAndConditionsContentFulfilled(
                            offersTermsAndConditionsContent
                          ),
                          clearTermsAndConditionsContent(),
                          resetBookings(),
                          setCurrencyCode({
                            currencyCode: selectPropertyCurrency(
                              searchParams.hotelCode
                            )(state),
                          }),
                          ...(shouldChangeRoute
                            ? [
                                replace(
                                  fetchedSearchResultsRouter({
                                    geoIpCountryCode,
                                    locale,
                                    searchParams,
                                  })({
                                    ...state,
                                    searchResults: {
                                      data: getHotelSearchResultsResponse,
                                    },
                                  })
                                ),
                              ]
                            : []),
                        ])
                      );
                    })
                  );
                }
              ),

              catchInternalServerError(),

              catchError(({ response = {} }) => [
                fetchSearchResultsFailed({
                  errors:
                    !response?.apiErrors && !response?.supplierErrors
                      ? [TECHNICAL_ERROR_MESSAGE]
                      : [],
                  apiErrors: response?.apiErrors || [],
                  supplierErrors: response?.supplierErrors || [],
                }),
                push({
                  pathname: employeeMode
                    ? profileRoutes.employeeProfilePath.to({ locale })
                    : bookingFlowRoutes.planYourStay.to({ locale }),
                  search: getQueryStringFromSearchFormValues(searchParams),
                }),
              ])
            )
          );
        })
      );
    })
  );
};

export default function fetchSearchResultsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchSearchResults.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            isAllInPricing = false,
            // locale,
            searchParams,
            refetch,
          },
        },
        state,
      ]) => {
        const { locale } = state.app;
        const propertyCode = searchParams.hotelCode;
        const property = selectProperty(propertyCode)(state);
        const isPropertyBookableInLocale = selectIsPropertyBookableInLocale({
          propertyCode,
          locale,
        })(state);

        if (!isPropertyBookableInLocale) {
          const continuePath = {
            ...state.router.location,
            pathname: getPathnameWithoutLocale(state.router.location.pathname),
          };
          return [
            missingLanguage({
              locale,
              property,
              continuePath,
            }),
            push(continuePath),
          ];
        }

        return fetchSearchResults$({
          isAllInPricing,
          locale,
          property,
          searchParams,
          refetch,
          state,
        }).pipe(takeUntil(action$.pipe(ofType(fetchSearchResultsCancel.type))));
      }
    )
  );
}
