import { ajax } from "rxjs/ajax";
import { pluck } from "rxjs/operators";
import creditCardType from "credit-card-type";

import env from "config/env";
import isMobileApp from "utils/isMobileApp";
import { basePostRequest } from "../baseRequest";

const { TRETAIL_API_BASE_URL } = env;
const TRETAIL_API_BOOKINGS_URL = `${TRETAIL_API_BASE_URL}/bookings`;

export const BOOKING_FOR_GUEST = "guest";
export const BOOKING_FOR_BOOKER = "booker";
export const BOOKING_FOR_AGENT = "agent";

const CARD_TYPE_MAPPINGS = {
  visa: "VISA",
  mastercard: "MASTERCARD",
  jcb: "JAPAN_CREDIT_BUREAU",
  "diners-club": "DINERS_CLUB",
  "american-express": "AMERICAN_EXPRESS",
  discovery: "DISCOVERY",
  discover: "DISCOVERY",
  "china-union-pay": "CHINA_UNION_PAY",
  unionpay: "CHINA_UNION_PAY",
  alipay: "ALIPAY",
};

function getSalesChannelHeader(salesChannel) {
  if (salesChannel) {
    return { "Sales-Channel": salesChannel.toUpperCase() };
  }
  if (isMobileApp()) {
    return { "Sales-Channel": "APP" };
  }
  return {};
}

export const completeBooking$ = ({
  locale,
  salesChannel,
  bookingId,
  suppressPaymentOptions = false,

  contactType = BOOKING_FOR_GUEST,
  bookerDetails,
  travelAgentDetails,
  guestDetails,
  creditCard,
  termsAndConditions,
  amount,
  gRecaptchaResponse,
  isUserPersistent,
}) => {
  const requestBody = {
    optInFSEmailComms: termsAndConditions.optInAgreement,
    gRecaptchaResponse,
    bookingEmailOptions: {
      sendGuestEmail: guestDetails?.sendGuestConfirmation ?? false,
    },
  };

  if (!isUserPersistent || contactType !== BOOKING_FOR_GUEST) {
    requestBody.mainGuest = {
      name: {
        firstName: guestDetails.firstName,
        surname: guestDetails.surname,
      },
      email: guestDetails.email,
      phone: {
        number: guestDetails.phoneNumber?.replace(/^\+/, ""),
        phoneType: "MOBILE",
      },
      address: {
        country: {
          code: guestDetails.countryCode,
        },
      },
    };
  }

  if (!suppressPaymentOptions) {
    requestBody.paymentDetails = {
      amount,
      creditCard: {
        cardHolderName: creditCard.cardHolderName,
        cardType:
          CARD_TYPE_MAPPINGS[
            (creditCardType(creditCard.number)[0] || {}).type
          ] || "",
        expiryDate: [
          creditCard.expiryDateMonth,
          creditCard.expiryDateYear,
        ].join(""),
        number: creditCard.number.replace(/\s/g, ""),
      },
    };
  }

  if (contactType === BOOKING_FOR_BOOKER) {
    if (isUserPersistent) {
      requestBody.booker = {
        contactType: "BOOK_FOR",
      };
    } else {
      requestBody.booker = {
        name: {
          firstName: bookerDetails.firstName,
          surname: bookerDetails.surname,
        },
        email: bookerDetails.email,
        phone: {
          number: bookerDetails.phoneNumber.replace(/^\+/, ""),
          phoneType: "MOBILE",
        },
        contactType: "BOOK_FOR",
      };
    }
  }

  if (contactType === BOOKING_FOR_AGENT) {
    if (isUserPersistent) {
      requestBody.booker = {
        contactType: "TRAVEL_AGENT",
        fsAdvisorId: travelAgentDetails?.fsAdvisorId,
        travelAgentId: travelAgentDetails.travelAgentId,
        travelAgencyName: travelAgentDetails.travelAgencyName,
        name: {
          firstName: travelAgentDetails.name,
        },
      };
    } else {
      requestBody.booker = {
        fsAdvisorId: travelAgentDetails?.fsAdvisorId,
        travelAgentId: travelAgentDetails.travelAgentId,
        travelAgencyName: travelAgentDetails.travelAgencyName,
        name: {
          firstName: travelAgentDetails.name,
        },
        email: travelAgentDetails.agentEmail,
        phone: {
          number: travelAgentDetails.phoneNumber.replace(/^\+/, ""),
          phoneType: "MOBILE",
        },
        contactType: "TRAVEL_AGENT",
      };
    }
  }

  return ajax({
    ...basePostRequest({
      locale,
      headers: getSalesChannelHeader(salesChannel),
    }),
    url: `${TRETAIL_API_BOOKINGS_URL}/${bookingId}/checkout${
      isUserPersistent ? "Persistent" : ""
    }`,
    body: requestBody,
  }).pipe(pluck("response"));
};

export default completeBooking$;
