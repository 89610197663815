import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatchWithLocale, useTranslation } from "hooks";

import {
  selectIsRequestFulfilled,
  selectAreSomeRequestsLoading,
} from "store/apiRequestStates";

import { selectEmployeeProfile } from "store/employeeProfile";
import {
  fetchGlobalSettings,
  selectGlobalSettings,
} from "store/globalSettings";
import { selectName } from "store/profile";
import { fetchProperties, selectProperties } from "store/properties";
import { fetchStayHistory, selectStayHistoryByYear } from "store/stayHistory";
import { getDateForEmployeeStayHistory } from "utils/datesHelpers";
import { parse } from "date-fns";

const defaultStayHistoryMonths = 18;

export default function useEmployeePortal() {
  const { locale } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const employee = useSelector(selectEmployeeProfile);
  const { stayHistoryMonths = defaultStayHistoryMonths } =
    useSelector(selectGlobalSettings) || {};
  const properties = useSelector(selectProperties);
  const employeeStayHistory = useSelector(selectStayHistoryByYear);
  const employeeName = useSelector(selectName);
  const haveGlobalSettings = useSelector(
    selectIsRequestFulfilled(fetchGlobalSettings.type)
  );
  const haveProperties = useSelector(
    selectIsRequestFulfilled(fetchProperties.type)
  );

  const [displayYears, setDisplayYears] = useState([new Date().getFullYear()]);

  useEffect(() => {
    if (!haveGlobalSettings) {
      dispatchWithLocale(fetchGlobalSettings());
    }
  }, [haveGlobalSettings]);

  useEffect(() => {
    if (!haveProperties) {
      dispatchWithLocale(fetchProperties());
    }
  }, [haveProperties]);

  useEffect(() => {
    if (haveGlobalSettings) {
      const stayHistoryStartDate = getDateForEmployeeStayHistory(
        stayHistoryMonths,
        employee
      );

      const stayHistoryDisplayYears = [];
      for (
        let year = new Date().getFullYear();
        year >=
        parse(stayHistoryStartDate, "yyyy-MM-dd", new Date()).getFullYear();
        year -= 1
      ) {
        stayHistoryDisplayYears.push(year);
      }
      setDisplayYears(stayHistoryDisplayYears);

      dispatchWithLocale(
        fetchStayHistory({
          locale,
          startDate: stayHistoryStartDate,
        })
      );
    }
  }, [haveGlobalSettings, stayHistoryMonths, employee]);

  const loadingData = useSelector(
    selectAreSomeRequestsLoading([
      fetchGlobalSettings.type,
      fetchStayHistory.type,
      fetchProperties.type,
    ])
  );

  return {
    employeeName,
    employeeStayHistory,
    properties,
    showLoadingIndicator: loadingData,
    displayYears,
  };
}
