import React from "react";

import "../../stylesheets/sass/profile_2018.scss";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { setView } from "store/app";
import { getIsoCodeForLocale } from "config/languages";
import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { getHMTLTagClassNames } from "utils";
import ProfileLayout from "../ProfileLayout";

import useEmployeeStayHistory from "./hooks/useEmployeeStayHistory";
import YearDisplay from "./components/YearDisplay";

function EmployeeStayHistoryView() {
  const {
    employeeName,
    employeeStayHistory,
    properties,
    showLoadingIndicator,
    displayYears,
  } = useEmployeeStayHistory();
  const { i18n, locale, t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(setView("EmployeeStayHistoryView"));

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
      </Helmet>
      <ProfileLayout>
        {showLoadingIndicator && <BookingFlowLoadingIndicator />}
        <div className="employee-stay-history">
          <div className="container">
            <h1 className="ty-h3">{t("Employee Stay History")}</h1>
            <hr className="small-divider" />
            <h1 className="user-name">
              {`${employeeName.firstName} ${employeeName.surname}`.trim()}
            </h1>
            <div className="stay-history">
              {displayYears.map((displayYear) => (
                <div className="year-history" key={displayYear}>
                  <YearDisplay
                    displayYear={displayYear}
                    properties={properties}
                    stays={
                      employeeStayHistory.find(
                        ({ year }) => year === displayYear
                      )?.stays
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ProfileLayout>
    </>
  );
}

export default EmployeeStayHistoryView;
