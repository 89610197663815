/* eslint-disable no-unused-vars */
import { createMiddleware } from "redux-beacon";

import { completeBookingFailed } from "store/bookings";

import { apmTarget, apmCaptureError } from "config/elasticApm";

const eventsMap = {
  [completeBookingFailed.type]: apmCaptureError(
    ({ payload: { errorContext } }) => ({
      error: "makeReservationFailed",
      errorContext,
    })
  ),
};

export default createMiddleware(eventsMap, apmTarget());
